import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import BreadcrumbComponent from './Breadcrumb'
import { t } from '@spa-core/locale'
import { Store } from '@spa-core/store'
import { getBreadcrumbKey } from '@spa-core/store/breadcrumbs/utils'
import { fetchBreadcrumbs } from '@spa-core/store/breadcrumbs/actions'
import { NAME as breadcrumbsReducerName } from '@spa-core/store/breadcrumbs/constants'
import { Breadcrumb } from '@spa-core/store/breadcrumbs/interfaces'

type Props = {
    typeOfPage?: string
    productCode?: string
    categoryCode?: string
    pageId?: string
    brand?: string
}

type ComponentState = {
    breadcrumbs: Breadcrumb[]
}

const Breadcrumbs: React.FC<Props> = ({ typeOfPage, productCode, categoryCode, brand, pageId }) => {
    const dispatch = useDispatch()
    const breadcrumbKey: string = getBreadcrumbKey(categoryCode, brand, productCode, pageId)

    const breadcrumbsStore = ({ reducers }: Store) => reducers[breadcrumbsReducerName].breadcrumbs
    const selector = createSelector(
        [breadcrumbsStore],
        (breadcrumbsStore): ComponentState => ({
            breadcrumbs: breadcrumbsStore[breadcrumbKey],
        }),
    )
    const { breadcrumbs }: ComponentState = useSelector(selector)

    useEffect(() => {
        if (!breadcrumbs) {
            dispatch(
                fetchBreadcrumbs({
                    typeOfPage,
                    categoryCode,
                    brand,
                    productCode,
                    pageId,
                }),
            )
        }
    }, [typeOfPage])

    return <BreadcrumbComponent breadcrumbs={breadcrumbs} homepageLabel={t('text.link.home.label')} />
}

export default Breadcrumbs
