import React from 'react'
import classnames from 'classnames'
import CardImage from './CardImage/CardImage'
import CardDescription from './CardDescription/CardDescription'
import CardStockStatus from './CardStockStatus/CardStockStatus'
import CardProductPrice from './CardProductPrice/CardProductPrice'
import CardButtons from './CardButtons/CardButtons'
import CardPopup from './CardPopup/CardPopup'
import { SlimProduct } from '@spa-core/store/products/interfaces'
import { ProductOneBuyStatus } from '@spa-core/store/products/constants'

type Props = {
    resolvedBackground: string
    slimProduct: SlimProduct
    buyButtonHandler: (currentlyAddingKey: string) => void
    subscriptionButtonHandler: () => void
    price: string
    formattedDiscountedPrice: string
    hasDiscount: boolean
    subscriptionIsOpen: boolean
    closeSubscribePopup: Function
    oneBuyStatus: ProductOneBuyStatus
    showBuyPanel: boolean
    topLeftPromo?: React.ReactNode
    topRightPromo?: React.ReactNode
    searchTerm?: string
    testFreaksItem?: React.ReactNode
}

const RowCard: React.FC<Props> = ({
    resolvedBackground,
    slimProduct,
    topLeftPromo,
    topRightPromo,
    searchTerm,
    buyButtonHandler,
    subscriptionButtonHandler,
    price,
    formattedDiscountedPrice,
    hasDiscount,
    subscriptionIsOpen,
    oneBuyStatus,
    showBuyPanel,
    closeSubscribePopup,
    testFreaksItem,
}) => {
    return (
        <div
            className={classnames(
                resolvedBackground,
                'flex flex-col md:flex-row justify-between p-2 w-full h-full e2e-ver-SlimRowCard',
            )}
        >
            <div className="flex flex-col md:flex-row md:justify-start md:w-1/2">
                <div className={'md:w-2/5 lg:w-1/5'}>
                    <CardImage
                        topLeftPromo={topLeftPromo}
                        topRightPromo={topRightPromo}
                        slimProduct={slimProduct}
                        searchTerm={searchTerm}
                    />
                    {testFreaksItem}
                </div>
                <div className="md:pl-4 self-center">
                    <CardDescription slimProduct={slimProduct} searchTerm={searchTerm} textLeft={true} />
                </div>
            </div>
            {/* Product bracket price section, placeholder. Bracket price component is not yet implementes for SlimRowCard */}
            <div
                className="flex flex-col md:flex-row md:justify-between w-full w-1/3 md:w-1/2 pt-2 md:pt-0"
                key="slimRowCard-buy-section"
            >
                {/* Stock status section for desktop */}
                <div className="hidden md:block md:self-center mx-2">
                    <CardStockStatus stockStatus={slimProduct.stockStatus} />
                </div>
                <div className="self-center">
                    <CardProductPrice
                        price={price}
                        formattedDiscountedPrice={formattedDiscountedPrice}
                        hasDiscount={hasDiscount}
                    />
                </div>
                <div className="w-full md:w-1/3 md:flex md:justify-end md:pr-2 self-center">
                    <CardButtons
                        slimProduct={slimProduct}
                        subscriptionIsOpen={subscriptionIsOpen}
                        subscriptionButtonHandler={subscriptionButtonHandler}
                        buyButtonHandler={buyButtonHandler}
                        oneBuyStatus={oneBuyStatus}
                    />
                </div>

                {/* Stock status section for mobile */}
                <div className="block md:hidden self-center">
                    <CardStockStatus stockStatus={slimProduct.stockStatus} />
                </div>
            </div>
            <CardPopup
                showBuyPanel={showBuyPanel}
                subscriptionIsOpen={subscriptionIsOpen}
                slimProduct={slimProduct}
                closeSubscribePopup={closeSubscribePopup}
            />
        </div>
    )
}

export default RowCard
