import React, { useState } from 'react'
import Button from '@ui-elem/Button/Button'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import Modal from '@ui-elem-js/Modal'
import { t } from '@spa-core/locale'
import { NavLink } from 'react-router-dom'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { useSelector } from 'react-redux'

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    openDeliveryInfoPopup: boolean
    setOpenDeliveryInfoPopup: Function
    personalizedTownCity: string
    fetchDeliveryOptionsForPostalCode: Function
    personalizedPostCode: string
    scrollUp: Function
}

const DeliveryInfoPopup: React.FC<Props> = ({
    openDeliveryInfoPopup,
    setOpenDeliveryInfoPopup,
    personalizedTownCity,
    fetchDeliveryOptionsForPostalCode,
    personalizedPostCode,
    scrollUp,
}) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    // Handle postcode localy before dispatch, reduces unneccesary re-rendering of page
    const [inputPostcode, setInputPostcode] = useState<string>()

    return (
        <Modal
            mode={'dialog'}
            padding={'none'}
            size={'extra-small'}
            open={openDeliveryInfoPopup}
            onClose={() => setOpenDeliveryInfoPopup(false)}
            className={'ic-inkclub-postcode-lookup'}
            closeIconSize={'large'}
            closeIcon={Icons.TimesCircle}
            key={'modal-postcode'}
        >
            <div className={'e2e-ver-postcode-lookup-popup p-4 mb-2 col-pale'}>
                <div className="text-base font-bold text-center flex flex-row justify-center">
                    {t('postalcode-lookup-title')}
                    <Icon className="ml-2 self-center" icon={Icons.MapMarkerAlt} size={13} />
                </div>
                {sessionConfig.userLoggedIn && personalizedPostCode && personalizedTownCity ? (
                    <>
                        <p className="text-center mb-2">{t('postcode.text.paragraph1')}</p>
                        <div className="flex justify-center">
                            <div className="col-panel p-2 mb-2 w-1/2">
                                <p className="text-center items-center">
                                    {personalizedPostCode && personalizedTownCity
                                        ? personalizedPostCode + ' ' + personalizedTownCity
                                        : t('checkout.form.postcode.invalid.empty')}
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <p className="text-center mb-2">{t('postcode.text.hintlogin')}</p>
                        <div className="flex justify-center">
                            <NavLink to={'/my-account'} activeClassName="active">
                                <Button
                                    onClick={() => setOpenDeliveryInfoPopup(false)}
                                    className="e2e-ver-postcode-login-button px-4 py-2"
                                    buttonText={t('login.login')}
                                />
                            </NavLink>
                        </div>
                    </>
                )}
                <div className="text-center">
                    <p>{t('postcode.text.paragraph2')}</p>
                </div>
                <div className="pt-4 flex justify-center">
                    <input
                        onKeyUp={(e) => {
                            if (e.key === 'Enter' && inputPostcode.length === 5) {
                                setOpenDeliveryInfoPopup(false)
                                fetchDeliveryOptionsForPostalCode(inputPostcode)
                            } else if (!/[0-9]/.test(e.key)) {
                                e.preventDefault()
                            }
                        }}
                        className="e2e-ver-postcode-input ml-2 w-1/2 border"
                        placeholder={t('postcode.input.placeholder')}
                        maxLength={5}
                        type="tel"
                        name="postCode"
                        onChange={(e) => setInputPostcode(e.target.value)}
                    />
                    <Button
                        onClick={() => {
                            if (inputPostcode?.length === 5) {
                                setOpenDeliveryInfoPopup(false)
                                fetchDeliveryOptionsForPostalCode(inputPostcode)
                                scrollUp()
                            }
                        }}
                        className="e2e-ver-postcode-search px-6 py-2 ml-2"
                        buttonText={t('text.button.choose')}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DeliveryInfoPopup
