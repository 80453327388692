import React, { useEffect, useState } from 'react'
import { t } from '@spa-core/locale'
import Link from '@ui-elem/Link'
import Button, { ButtonColors } from '@ui-elem/Button/Button'
import { PersonalizedProduct } from '@spa-core/store/app/interfaces'
import classNames from 'classnames'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

type ComponentState = {
    sessionConfig: SessionConfig
}

enum Styles {
    OFFER_BANNER_COLOR = 'offer_banner_color',
}

enum StickerValue {
    DEFAULT_STICKER = '1krStickerFi.png',
    DK_NO_STICKER = '1krStickerDkNo.png',
    SE_STICKER = '1krSticker.png',
    CHF_STICKER = '1krSticker_chf.png',
    NL_STICKER = '1krSticker_pre_euro.png',
}

enum Sticker {
    se = StickerValue.SE_STICKER,
    at = StickerValue.DEFAULT_STICKER,
    ch = StickerValue.CHF_STICKER,
    nl = StickerValue.NL_STICKER,
    no = StickerValue.DK_NO_STICKER,
    fr = StickerValue.DEFAULT_STICKER,
    de = StickerValue.DEFAULT_STICKER,
    dk = StickerValue.DK_NO_STICKER,
    be = StickerValue.DEFAULT_STICKER,
    fi = StickerValue.DEFAULT_STICKER,
    default = StickerValue.SE_STICKER,
}

type Props = {
    personalizedProduct: PersonalizedProduct
}

const EnKrOffer: React.FC<Props> = ({ personalizedProduct }) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[sessionReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)
    const countryIso: string = sessionConfig.countryIso ? sessionConfig.countryIso.toLowerCase() : 'default'

    const [resolvedOfferImage, setResolvedOfferImage] = useState<string>()

    useEffect(() => {
        personalizedProduct.typeCode === 't1'
            ? setResolvedOfferImage(`${sessionConfig.commonResourcePath}/images/1kr-banner-printer.png`)
            : null
        personalizedProduct.typeCode === 't5'
            ? setResolvedOfferImage(`${sessionConfig.commonResourcePath}/images/1kr-banner-dustbags.png`)
            : null
    }, [personalizedProduct, setResolvedOfferImage, sessionConfig])

    const rootPath: string = 'images/enkrstickers/'

    return (
        <div className={classNames(Styles.OFFER_BANNER_COLOR, 'relative flex w-full px-4 pt-2 md:pt-0 mb-1')}>
            <div className="e2e-left-col justify-around flex flex-col">
                <div className="text-2xl md:text-3xl font-bold w-5/6 font-spec">{personalizedProduct.description}</div>
                <Link to={personalizedProduct.url} key={personalizedProduct.code}>
                    <Button
                        buttonColor={ButtonColors.SECONDARY}
                        buttonPadding={'px-10 py-2'}
                        buttonText={t('button.text.clickHere')}
                    />
                </Link>
                <div className="text-sm my-1 w-4/5 md:w-full text-sm">
                    {personalizedProduct.typeCode === 't1' ? t('phrase.1kr.disclaimer.ink') : null}
                    {personalizedProduct.typeCode === 't5' ? t('phrase.1kr.disclaimer.dustbag') : null}
                </div>
            </div>
            <div className="w-12 md:w-44 text-right">
                <img src={resolvedOfferImage} className="w-32 md:w-36 absolute top-16 md:top-6 right-2" />

                {personalizedProduct.isEnkrona ? (
                    <img
                        className="w-16 absolute top-8 md:top-2 right-1 md:right-2"
                        src={`${sessionConfig.commonResourcePath}/${rootPath}${
                            Sticker[countryIso] ? Sticker[countryIso] : Sticker.default
                        }`}
                    />
                ) : null}
            </div>
        </div>
    )
}

export default EnKrOffer
