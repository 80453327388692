// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
/* eslint-disable */
import AccountMod from '~/packages/spa-ec/src/components/Account/AccountMod.theme-minespresso.js';
import BasicHeader from '~/packages/spa-ec/src/components/Header/BasicHeader.theme-minespresso.js';
import ContactUsPage from '~/packages/spa-ec/src/routes/ContactUsPage/ContactUsPage.theme-minespresso.js';
import ProductRow from '~/packages/spa-ec/src/components/Account/Subscription/ProductRow.theme-minespresso.js';
import ProfileMod from '~/packages/spa-ec/src/components/Profile/ProfileMod.theme-minespresso.js';

const overrides = {
  AccountMod,
  BasicHeader,
  ContactUsPage,
  ProductRow,
  ProfileMod,
};
export default overrides;

// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
