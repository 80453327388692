import Carousel from '@ui-elem/Carousel/Carousel'
import React from 'react'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import MainImage from './MainImage'
import Modal from '@ui-elem-js/Modal'
import { Icons } from '@ui-elem/Icon/Icon'

const fixImages = (thumbnail: any, enlargedImages: any) => {
    let i: number
    const res = []
    for (i = 0; i < Math.min(thumbnail.length, enlargedImages.length); i++) {
        res.push({
            original: enlargedImages[i].url,
            thumbnail: thumbnail[i].url,
        })
    }

    return res
}

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    setImageGalleryOpen: Function
    getImgFromProd: Function
    setSelectedImageIndex: any
    selectedImageIndex: number
    product: any
}

const Gallery: React.FC<Props> = ({
    setImageGalleryOpen,
    setSelectedImageIndex,
    getImgFromProd,
    selectedImageIndex,
    product,
}) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    return (
        <Modal
            className="modal-content"
            mode={'dialog'}
            onClose={() => setImageGalleryOpen(false)}
            closeIcon={Icons.TimesCircle}
            closeIconSize={'medium'}
            closeIconPos={'symmetric'}
            size={'medium'}
        >
            <Modal.Content>
                <div className="p-2 aligne-middle">
                    {product.images.xlarge.length === 0 ? (
                        <img
                            key={product.code}
                            src={`${sessionConfig.themeResourcePath}/images/placeholder.svg`}
                            className="w-4/5 aspect-square"
                        />
                    ) : null}

                    <Carousel
                        dotsOutsideComponent={true}
                        initialSlide={selectedImageIndex}
                        setSelectedImageIndex={setSelectedImageIndex}
                    >
                        {fixImages(product.images.small, product.images.xlarge).map((_: any, index: number) => {
                            const url: string = getImgFromProd(
                                product,
                                index,
                                `${sessionConfig.themeResourcePath}/images/placeholder.svg`,
                            )
                            return (
                                <MainImage
                                    key={product.code}
                                    url={url}
                                    name={product.name}
                                    manufacturer={product.manufacturer}
                                    classes={'w-fit flex items-center aspect-square align-middle m-auto'}
                                    imageWidth="w-[278px] md:w-[400px]"
                                    imageHeight="h-[278px] md:h-[400px]"
                                />
                            )
                        })}
                    </Carousel>
                </div>
            </Modal.Content>
        </Modal>
    )
}

export default Gallery
