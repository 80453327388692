import React from 'react'
import classnames from 'classnames'

type Props = {
    url: string
    name: string
    manufacturer: string
    openGallery?: () => void
    classes?: string
    imageWidth?: string
    imageHeight?: string
}

const MainImage: React.FC<Props> = ({
    url,
    name,
    manufacturer,
    openGallery,
    classes = '',
    imageWidth = 'w-[278px] md:w-[295px]',
    imageHeight = 'h-[278px] md:h-[295px]',
}) => {
    return (
        <div className="flex w-full justify-center">
            {/* Setting the Placeholder image width and height for Firefox to be able to render the SVG. */}
            <div className={classnames(url?.includes('placeholder') ? `${imageWidth} ${imageHeight}` : '')}>
                <img
                    src={url}
                    id={'test-main-img'}
                    onClick={() => openGallery?.()}
                    alt={manufacturer + ' alt ' + name}
                    title={manufacturer + ' ' + name}
                    className={classnames(classes, imageWidth, imageHeight, 'cursor-pointer')}
                />
            </div>
        </div>
    )
}

export default MainImage
