import React, { useCallback, useEffect } from 'react'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getComponentTheme } from '@ic-theme'
import { NAME, PrisjaktCheapestTheme } from './PrisjaktCheapest.theme'
import debounce from '@spa-core/util/debounce'

export enum Formats {
    sm1 = 'small1',
    sm2 = 'small2',
    sm3 = 'small3',
    sm4 = 'small4',
    md1 = 'medium1',
    md2 = 'medium2',
}

const theme: PrisjaktCheapestTheme = getComponentTheme<PrisjaktCheapestTheme>(NAME)

const PRISJAKT_CHEAPEST: string = 'prisjakt_cheapest'

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    rightPosition?: boolean
    code: string
    size: Formats
}

const PrisjaktCheapest: React.FC<Props> = ({ rightPosition = false, code, size }) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    const debouncedReload = useCallback(
        () =>
            debounce(() => {
                window?.[PRISJAKT_CHEAPEST].reload()
            }, 100),
        [window],
    )

    useEffect(() => {
        if (window?.[PRISJAKT_CHEAPEST]) {
            setTimeout(() => {
                debouncedReload()
            }, 500)
        }
    }, [])

    const storeId: number = theme?.storeIds?.[sessionConfig.currentBaseStoreId]

    if (!window?.[PRISJAKT_CHEAPEST] || !storeId) return

    const marketDomain: string = sessionConfig.currentBaseStoreId.indexOf('-no') > -1 ? 'NO' : 'SE'

    return (
        <div
            className={`prisjakt_cheapest z-10 absolute bottom-2.5 ${rightPosition ? 'right-1.5' : 'left-1.5'}`}
            data-shop={storeId}
            data-product={code}
            data-market={marketDomain}
            data-format={size}
        />
    )
}

export default PrisjaktCheapest
