import React, { useState } from 'react'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import { t } from '@spa-core/locale'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { NAME as productsReducerName } from '@spa-core/store/products/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import AnonymizerPopup from '@spa-ec/components/PersonalizedCockpit/GreetingsBanner/AnonymizerPopup'
import { SlimProduct } from '@spa-core/store/products/interfaces'

type ComponentState = {
    sessionConfig: SessionConfig
    repurchasebleProducts: SlimProduct[]
}

type Props = {
    personalizedName: string
    hasPersonalizedproducts: boolean
}

const GreetingsBanner: React.FC<Props> = ({ personalizedName, hasPersonalizedproducts }) => {
    const [openAnonymizerPopup, setOpenAnonymizerPopup] = useState<boolean>(false)
    const sessionConfigStore = ({ reducers }: Store) => reducers[sessionReducerName].sessionConfig
    const repurchasebleProductsStore = ({ reducers }: Store) => reducers[productsReducerName].repurchasebleProducts

    const selector = createSelector(
        [sessionConfigStore, repurchasebleProductsStore],
        (sessionConfig, repurchasebleProducts): ComponentState => ({
            sessionConfig,
            repurchasebleProducts,
        }),
    )
    const { sessionConfig, repurchasebleProducts }: ComponentState = useSelector(selector)

    return (
        <>
            <div className="border-box mb-1 mr-1">
                <div className="flex items-center">
                    <div>
                        <Icon className={'mx-2'} icon={Icons.User} size={48} />
                    </div>
                    <div className="ml-2 w-full relative">
                        <h4 className="text-base m-0">{t('personalized.greeting', { 0: personalizedName.split(' ')[0] })}</h4>
                        {/* Skippa vid avsaknad av både modeller och senaste köp */}
                        {repurchasebleProducts?.length || hasPersonalizedproducts ? (
                            <>
                                {t('personalized.waiting.favourites')}
                                <br />
                            </>
                        ) : null}
                        {t('personalized.needs.query')}
                        {sessionConfig.showForgetMeButton ? (
                            <button
                                className={'flex flex-row justify-end cursor-pointer mt-1'}
                                onClick={() => setOpenAnonymizerPopup(true)}
                            >
                                <span className="underline text-sm">{t('phrase.forget.me')}</span>
                                <Icon className={'ml-1 self-center'} icon={Icons.Trash} size={14} />
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
            <AnonymizerPopup openAnonymizerPopup={openAnonymizerPopup} setOpenAnonymizerPopup={setOpenAnonymizerPopup} />
        </>
    )
}

export default GreetingsBanner
