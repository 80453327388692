import React from 'react'
import { t } from '@spa-core/locale'
import Button, { ButtonColors, IconAlignments } from '@ui-elem/Button/Button'
import { Icons } from '@ui-elem/Icon/Icon'
import Modal from '@ui-elem-js/Modal'
import { useCookies } from 'react-cookie'

type Props = {
    openAnonymizerPopup: boolean
    setOpenAnonymizerPopup: Function
}

const AnonymizerPopup: React.FC<Props> = ({ openAnonymizerPopup, setOpenAnonymizerPopup }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie, removeCookie] = useCookies(['ID2', 'JSESSIONID'])

    const text1: string = t('anonyminizer.text.paragraph1')
    const text2: string = t('anonyminizer.text.paragraph2')

    return (
        <Modal
            mode={'dialog'}
            padding={'none'}
            size={'extra-small'}
            open={openAnonymizerPopup}
            onClose={() => setOpenAnonymizerPopup(false)}
            className={'ic-inkclub-divider'}
            closeIconSize={'small'}
            radius={'0.735'}
            key={'modal-forget'}
        >
            <div className={'p-8 col-pale-inverted'}>
                <div className="text-x1 font-bold text-center">{t('anonyminizer.popup.title')}</div>
                <p>{text1}</p>
                <p>{text2}</p>
                <div className="flex justify-around">
                    <Button
                        onClick={() => setOpenAnonymizerPopup(false)}
                        className="px-8 py-2"
                        buttonText={t('text.button.cancel')}
                    />
                    <Button
                        buttonIcon={Icons.Trash}
                        iconAlignment={IconAlignments.RIGHT}
                        onClick={() => {
                            removeCookie('ID2', { path: '/' })
                            removeCookie('JSESSIONID', { path: '/' })
                            location.reload()
                        }}
                        buttonColor={ButtonColors.TERTIARY}
                        className="px-8 py-2"
                        buttonText={t('text.button.clear')}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default AnonymizerPopup
