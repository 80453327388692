import each from 'lodash/each';
import find from 'lodash/find';
import { Logger } from '@spa-core/logger';
import { t } from '@spa-core/locale';
import { getThemeFactory } from '@ic-theme';
import { getRefinedValue as filterText } from '@ui-elem/CleanText/CleanText';
import filter from 'lodash/filter';
import cloneDeep from 'fast-clone';
import { NAME as appReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

const theme = getThemeFactory('Product-factories', {})();

const exctractFeature = (arr, code, defaultValue) => {
  const val = find(arr, {
    code,
  });

  if (val) {
    return val.featureValues[0].value;
  }

  return defaultValue;
};

export const STR_HAS_HIGHLIGHT_PATTERN_REGEX = new RegExp('<em[^>]*>(:?.*)</em>', 'g');

const extractClassifications = (classifications) => {
  const cfs = [];
  if (classifications && classifications.length > 0) {
    classifications.forEach((c) => {
      if (c.features && c.features.length > 0) {
        c.features.forEach((f) => {
          if (f.featureValues && f.featureValues.length > 0 && f.featureValues.length === 1) {
            cfs.push([f.name, `${f.featureValues[0].value}${f.featureUnit ? ' ' + f.featureUnit.symbol : ''}`, f.code]);
          }
          if (f.featureValues && f.featureValues.length > 0 && f.featureValues.length > 1) {
            let featureValuesList = [];
            f.featureValues.map((value) => featureValuesList.push(value.value));
            cfs.push([f.name, `${featureValuesList}${f.featureUnit ? ' ' + f.featureUnit.symbol : ''}`, f.code]);
          }
        });
      }
    });
  }
  return cfs;
};

const extractCertifications = (classifications) => {
  const certifications = { name: '', values: [] };
  const enviromentClassification = find(classifications, {
    code: 'environmental',
  });
  if (enviromentClassification && enviromentClassification.features) {
    enviromentClassification.features.forEach((feature) => {
      if (feature.code === 'InkclubClassification/1.0/environmental.certification') {
        certifications.name = feature.name;

        feature.featureValues.forEach((value) => {
          let certificationOption;
          switch (value.value) {
            case 'certi_AAA':
            case 'Astma och Allergiförbundet':
            case 'Astma- og allergiforeningen':
            case 'Astma- och Allergiförbundet (Astma-Allergi)':
            case 'Astma- ja allergiayhdistys':
              certificationOption = { name: value.value, logo: 'astma_&_allergiforbundet_150x150.jpeg' };
              break;

            case 'certi_SNF':
            case 'Bra Miljöval':
            case 'Godt miljøvalg':
            case 'Miljøvenligt valg':
            case 'Hyvä ympäristövalinta':
              certificationOption = { name: value.value, logo: 'bra_miljoval_150x150.jpeg' };
              break;

            case 'certi_BA':
            case 'Blue Angel':
              certificationOption = { name: value.value, logo: 'der_blauer_engel_150x150.jpeg' };
              break;

            case 'certi_EUEL':
            case 'EU Ecolabel':
              certificationOption = { name: value.value, logo: 'eu-ecolabel_150x150.jpeg' };
              break;

            case 'certi_EUP':
            case 'Europalövet':
            case 'Europaløvet':
            case '"EU\'s grønne blad"':
            case 'Eurolehti':
              certificationOption = { name: value.value, logo: 'europa_lovet_council_150x150.jpeg' };
              break;

            case 'certi_FT':
            case 'Fairtrade':
            case 'Reilu kauppa':
              certificationOption = { name: value.value, logo: 'fairtrade_150x150.jpeg' };
              break;

            case 'certi_FSC':
            case 'Forest Stewardship Council':
            case 'Skogforvaltningsrådet (FSC)':
            case 'Forest Stewardship Council (FSC)':
              certificationOption = { name: value.value, logo: 'forest_stewardship_council_150x150.jpeg' };
              break;

            case 'certi_KM':
            case 'Krav-märkt':
            case 'Krav-merket':
            case 'Krav-mærket':
            case 'Krav-merkitty':
              certificationOption = { name: value.value, logo: 'krav_150x150.jpeg' };
              break;

            case 'certi_PEFC':
            case 'Pan European Forest Certifikation':
            case 'Pan europeisk skogsertifisering (PEFC)':
            case 'Pan European Forest Certifikation (PEFC)':
              certificationOption = { name: value.value, logo: 'pefc_150x150.jpeg' };
              break;

            case 'certi_RA':
            case 'Rainforest Alliance':
            case 'Regnskogsalliansen':
              certificationOption = { name: value.value, logo: 'rainforest_alliance_150x150.jpeg' };
              break;

            case 'certi_SVN':
            case 'Svanen':
            case 'Joutsen':
              certificationOption = { name: value.value, logo: 'svanen_150x150.jpeg' };
              break;

            case 'certi_UTZ':
            case 'UTZ Certified':
            case 'UTZ-sertifisert':
            case 'UTZ-certificeret':
            case 'UTZ-sertifioitu':
              certificationOption = { name: value.value, logo: 'utz_certified_150x150.jpeg' };
              break;
            default:
              break;
          }
          if (certificationOption) {
            certifications.values.push(certificationOption);
          }
        });
      }
    });
  }
  return certifications;
};

const resolveImage = (imageArray, pImageSrc) => {
  return imageArray.length > 0 ? imageArray[0] : { url: pImageSrc };
};

function cleant(input) {
  return input;
}

function extractSummaryText(summary) {
  if (!summary) {
    return '';
  }
  let summ = summary !== null && summary.indexOf('>') > -1 ? summary : cleant(summary);
  if (summ) {
    summ = filterText(summ, ['br']);
  }
  return summ;
}

/* eslint-disable */
export const getProductStockStatus = (productData) => {
  return getStockStatus(productData.stockStatus);
};

export const getStockStatus = (sourceStockStatus) => {
  if (sourceStockStatus) {
    let stockStatus = {
      availability:
        typeof sourceStockStatus === 'object'
          ? sourceStockStatus.availabilityEnum
          : sourceStockStatus,
      stockStatusDescription:
        sourceStockStatus.productStockStatusCode === 'NewProductProlongedDeliveryTime_761' ||
          sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToThreeWeeks_781' ||
          sourceStockStatus.productStatusCode === 'ToBeDiscontinued_256' ||
          sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToOneWeek_2' ||
          sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToOneWeek_711' ||
          sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToThreeWeeks_5' ||
          sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToThreeWeeks_781' ||
          sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToTwoWeeks_4' ||
          sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToTwoWeeks_721'

          ? 'product.stockStatus.' + sourceStockStatus.productStockStatusCode
          : 'stockstatus.' + sourceStockStatus.availabilityEnum.toLowerCase(),
    };
    return stockStatus;
  }
};

const getShortCopy = (productData) => {
  if (productData.promotionalText) {
    return null;
  }
  let sCopyMobile = extractSummaryText(productData.summary) || '';
  let sCopyDesktop = sCopyMobile;
  // let longText = false;
  // if ( true && sCopyMobile && sCopyMobile.length > 30) {
  if (sCopyMobile && sCopyMobile.length > 30) {
    sCopyMobile = sCopyMobile.substring(0, 35);
    const lastSpace = sCopyMobile.lastIndexOf(' ');
    sCopyMobile = `${sCopyMobile.substring(0, lastSpace)}...`;
    // longText = true;
  }
  // if (true && sCopyDesktop && sCopyDesktop.length > 50) {
  if (sCopyDesktop && sCopyDesktop.length > 50) {
    sCopyDesktop = sCopyDesktop.substring(0, 60);
    const lastSpace = sCopyDesktop.lastIndexOf(' ');
    sCopyDesktop = `${sCopyDesktop.substring(0, lastSpace)}...`;
  }
  return { sCopyMobile, sCopyDesktop }
}

export const getProduct = (_productData) => {
  const productData = cloneDeep(_productData);
  if (productData.slimDTO) {
    return getServerBasicProduct(productData);
  }
  const coffee = find(productData.classifications, {
    code: 'coffee',
  });

  let energySymbol;
  let energyLabel;
  const lightclub = filter(productData.classifications, (c) => c.code === 'lightclub');
  const energy =
    lightclub.length > 0
      ? filter(lightclub[0].features, (c) => c.code === 'InkclubClassification/1.0/lightclub.energyclass')
      : [];
  if (energy.length > 0) {
    try {
      energySymbol = energy[0].featureValues[0].value;
      energySymbol = energySymbol.replace(/\+/g, '-plus');
      energyLabel = energy[0].name.replace('<b>', '').replace('</b>', '');
    } catch {
      energySymbol = undefined;
    }
  }
  const pImageSrc = `${window?.['sessionConf']?.themeResoucePath}/${theme.config.placeholderImageSrc}`;
  let productDiscountPrice = '';
  let price = productData.price.value;
  let costPerPage = productData.costPerPage?.value;
  // Todo price inconsistency in the backend has to be removed
  if (productData.discountPrice && productData.discountPrice !== null) {
    productDiscountPrice = productData.discountPrice.value;
  } else if (
    (typeof productData.discountPrice === 'undefined' || productData.discountPrice === null) &&
    productData.basePrice !== null &&
    typeof productData.basePrice !== 'undefined'
  ) {
    productDiscountPrice = productData.price.value;
    price = productData.basePrice.value;
  }

  const newProdEntry = {
    name: productData.name ? cleant(productData.name) : cleant(productData.title),
    code: productData.code,
    url: sanitizeProductUrl(productData.url),
    images: productData.imagesSubset,
    price,
    costPerPage,
    discountedPrice: productDiscountPrice,
    currency: productData.price.currencyIso,
    text: extractSummaryText(productData.summary),
    summary: extractSummaryText(productData.summary),
    description: productData.description,
    isTrialProduct: productData.trialProduct,
    manufacturer: cleant(productData.manufacturer),
    oneBuyOnlyProduct: productData.oneBuyOnlyProduct,
    oneBuyUserGroupId: productData.oneBuyUserGroupId,
    discountPercentage: productData.discountPercentage,
    productInfoMessage: productData.productInfoMessage,
    oneBuyOnlyPromotionPrice: productData.oneBuyOnlyPromotionPrice,
    virtualShelfPageId: productData.virtualShelfPageId,
    productType: productData.productType,
    unitsPerPackage: productData.unitsPerPackage !== null ? productData.unitsPerPackage : 1,
    productStockStatus: getProductStockStatus(productData),
    printerModelCategoryCode: productData.printerModelCategoryCode,
    // Used by dammsugarpasar SPA
    status:
      productData.stockStatus.availabilityEnum !== undefined ? productData.stockStatus.availabilityEnum : productData.status,
    // Used by dinvitamin to know if hpem many items of a product you are allowed to buy
    productQuantityThreshold: productData.productQuantityThreshold,
    subscriptionIsAvailable: productData.subscribable,
    correspondsTo: productData.correspondsTo,
    productThumbnailUrl: productData.thumbnailUrl,
    bracketPrices: productData.bracketPrices,
    specification: productData.specification,
    certifications: extractCertifications(productData.classifications),
    classifications: productData.classifications != null ? extractClassifications(productData.classifications.filter((data) => data.code !== 'environmental')) : null,
    colorCode: productData.colorCode,
    color: productData.color,
    manufacturerProductNumber: productData.manufacturerProductNumber,
    breadcrumbs: productData.breadcrumbs,
    energySymbol,
    energyLabel,
    isSnusProduct: productData.snusProduct,
  };

  if (coffee) {
    newProdEntry.strength = exctractFeature(coffee.features, 'InkclubClassification/1.0/coffee.strength', null);
    newProdEntry.acidity = exctractFeature(coffee.features, 'InkclubClassification/1.0/coffee.acidity', null);
    newProdEntry.body = exctractFeature(coffee.features, 'InkclubClassification/1.0/coffee.body', null);
    newProdEntry.roasting = exctractFeature(coffee.features, 'InkclubClassification/1.0/coffee.roastingvalue', null);
  }

  if (!newProdEntry.images) {
    Logger.info('Weird product', newProdEntry.code);
    newProdEntry.images = {
      small: [{ url: pImageSrc }],
      medium: [{ url: pImageSrc }],
      large: [{ url: pImageSrc }],
      xlarge: [{ url: pImageSrc }],
      primary: {
        small: { url: pImageSrc },
        medium: { url: pImageSrc },
        large: { url: pImageSrc },
        xlarge: { url: pImageSrc },
      },
    };
  } else {
      newProdEntry.images.primary = {
        small: resolveImage(newProdEntry.images.small, pImageSrc),
        medium: resolveImage(newProdEntry.images.medium, pImageSrc),
        large: resolveImage(newProdEntry.images.large, pImageSrc),
        xlarge: resolveImage(newProdEntry.images.xlarge, pImageSrc),
      }
  }

  // Adding a unique id to thumnails to avoid key warning in console
  each(newProdEntry.images.small, (item, pos) => {
      item.key = productData.code + '_thumb_' + pos;  
    
  });
  if (!newProdEntry.summary) {
    newProdEntry.summary = '';
  }

  if (productData.modelCategoryCode != null) {
    newProdEntry.modelCategoryCode = productData.modelCategoryCode;
  }
  // Meta-data
  newProdEntry.title = cleant(productData.title);
  newProdEntry.metaTitle = productData.metaTitle;
  newProdEntry.metaDescription = productData.metaDescription;
  newProdEntry.description = productData.description;
  newProdEntry.keywords = productData.keywords;
  newProdEntry.oneBuyOnlyPromotionPrice = productData.oneBuyOnlyPromotionPrice;
  newProdEntry.pageYield = productData.pageYield;
  newProdEntry.inkVolume = productData.inkVolume;
  newProdEntry.environmental = productData.environmental;
  return newProdEntry;
};

export const getBasicProduct = (productData) => {

  if(productData.slimDTO) {
    return getServerBasicProduct(productData);
  }
  // Basic Product Factory - Energy Label
  let energySymbol;
  let energyLabel;
  const lightclub = filter(productData.classifications, (c) => c.code === 'lightclub');
  const energy =
    lightclub.length > 0
      ? filter(lightclub[0].features, (c) => c.code === 'InkclubClassification/1.0/lightclub.energyclass')
      : [];
  if (energy.length > 0) {
    try {
      energySymbol = energy[0].featureValues[0].value;
      energySymbol = energySymbol.replace(/\+/g, '-plus');
      energyLabel = energy[0].name.replace('<b>', '').replace('</b>', '');
    } catch {
      energySymbol = undefined;
    }
  }

  // Basic Product Factory - Product Image Placeholder
  const sessionConfig = getReducer(appReducerName).sessionConfig;
  const pImageSrc = `${sessionConfig.themeResoucePath}/${theme.config.placeholderImageSrc}`;

  let discountedPrice = productData.price.value;
  let price = productData.price.value;
  let costPerPage = productData.costPerPage?.value;
  if (productData.discountPrice && productData.discountPrice !== null) {
    discountedPrice = productData.discountPrice.value;
  } else if (
    (typeof productData.discountPrice === 'undefined' || productData.discountPrice === null) &&
    productData.basePrice !== null &&
    typeof productData.basePrice !== 'undefined'
  ) {
    discountedPrice = productData.price.value;
    price = productData.basePrice.value;
  }

  const { sCopyDesktop, sCopyMobile } = getShortCopy(productData);

  if (!productData.bracketPrices) {
    productData.bracketPrices = [];
  }

  // Basic Product Factory - Extract vital information from product data, about brackt price
  const slimBracketPrice = productData.bracketPrices.map((bracketPrices) => ({
    quantity: bracketPrices.quantity,
    quantityPrice: bracketPrices.bracketPrice.longFormattedValue,
    quantityPriceValue: bracketPrices.bracketPrice.value,
  }));

  const newProdEntry = {
    name: productData.name ? productData.name : productData.title,
    code: productData.code,
    url: sanitizeProductUrl(productData.url),
    imgUrl: productData.imagesSubset ? resolveImage(productData.imagesSubset.medium, pImageSrc).url : '',
    price,
    costPerPage,
    discountedPrice,
    currency: productData.price.currencyIso,
    title: productData.name ? cleant(productData.name) : cleant(productData.title),
    text: { sCopyDesktop, sCopyMobile },
    oneBuyOnlyProduct: productData.oneBuyOnlyProduct,
    oneBuyUserGroupId: productData.oneBuyUserGroupId,
    infoMessage: productData.productInfoMessage,
    oneBuyOnlyPromotionPrice: productData.oneBuyOnlyPromotionPrice,
    stockStatus: getProductStockStatus(productData),
    subscriptionIsAvailable: productData.subscribable,
    bracketPrices: slimBracketPrice,
    energySymbol,
    energyLabel,
    // Used by dinvitamin to know if hpem many items of a product you are allowed to buy
    productQuantityThreshold: productData.productQuantityThreshold,
    isSnusProduct: productData.snusProduct,
  };

  if (productData.modelCategoryCode != null) {
    newProdEntry.modelCategoryCode = productData.modelCategoryCode;
  }
  return newProdEntry;
};

//santize product url to remove query string and decode url
export const sanitizeProductUrl = (url) => {
  if (url) {
  // if url contains a query string, remove it
  url.replace(/\?.*/, '');

  // decode the url
  url = decodeURIComponent(url);

  // if url contains a '+' sign, replace it with a 'plus'
  url = url.replace(/\+/g, 'plus');
  }
  return url;
};

const getServerBasicProduct = (product) => {
  const newProdEntry = {
    slimDTO: product.slimDTO,
    name: product.title ? product.title : product.name,
    code: product.code,
    url: sanitizeProductUrl(product.url),
    imgUrl: product.imgUrl ? product.imgUrl : '',
    price: product.price,
    costPerPage: product.costPerPage,
    discountedPrice: product.discountedPrice,
    currency: product.currency,
    title: product.title ? cleant(product.title) : cleant(product.name),
    text: {
      sCopyDesktop: product.text ? product.text.scopyDesktop : '',
      sCopyMobile: product.text ? product.text.scopyMobile : ''
    },
    oneBuyOnlyProduct: product.oneBuyOnlyProduct,
    oneBuyUserGroupId: product.oneBuyUserGroupId,
    infoMessage: product.infoMessage,
    oneBuyOnlyPromotionPrice: product.oneBuyOnlyPromotionPrice,
    stockStatus: product.stockStatus,
    subscriptionIsAvailable: product.subscribable || product.subscriptionIsAvailable,
    bracketPrices: product.bracketPrices,
    energySymbol: product.energySymbol,
    energyLabel: product.energyLabel,
    // Used by dinvitamin to know if hpem many items of a product you are allowed to buy
    productQuantityThreshold: product.productQuantityThreshold,
    isSnusProduct: product.snusProduct,
    printetModelCategoryCode: product.printetModelCategoryCode,
    solrDocScore: product.solrDocScore,
  }
  if (product.correspondsTo && product.correspondsTo.match(STR_HAS_HIGHLIGHT_PATTERN_REGEX)) {
    newProdEntry.text.sCopyDesktop = `${t("product.code.replaces")} ${
      product.correspondsTo
    }`;
    newProdEntry.text.sCopyMobile = `${t("product.code.replaces")} ${
      product.correspondsTo
    }`;
  }
  return newProdEntry;
}

// Takes a std product not a a raw one but standard product and returns a basic product
export const transformToBasicProduct = (productData, isLatestPurches) => {
  if (productData.slimDTO) {
    return productData;
  }
  let transformedProduct = getBasicProduct(productData);

  transformedProduct.imgUrl = productData.productThumbnailUrl;

  // Todo eliminate duplicate field
  transformedProduct.stockStatus = productData.productStockStatus;
  transformedProduct.productStockStatus = productData.productStockStatus;
  transformedProduct.images = productData.images;
  transformedProduct.price = productData.price;
  transformedProduct.costPerPage = productData.costPerPage;
  transformedProduct.discountedPrice = productData.discountedPrice;
  transformedProduct.subscriptionIsAvailable = productData.subscriptionIsAvailable
  transformedProduct.currency = productData.currency;
  transformedProduct.metaDescription = productData.metaDescription;
  transformedProduct.summary = productData.summary;
  transformedProduct.manufacturer = productData.manufacturer;
  if (!transformedProduct.text) {
    transformedProduct.text = {};
  }
  if (!transformedProduct.text.sCopyMobile) {
    transformedProduct.text.sCopyMobile = '';
  }
  if (!transformedProduct.text.sCopyDesktop) {
    transformedProduct.text.sCopyDesktop = '';
  }

  if (isLatestPurches) {
    const sessionConfig = getReducer(appReducerName).sessionConfig;
    const placeholderImageSrc = `${sessionConfig.themeResoucePath}/${theme.config.placeholderImageSrc}`;
    transformedProduct.imgUrl = resolveImage(productData.images.medium, placeholderImageSrc).url;
  }
  if (productData.code && (!transformedProduct.energySymbol || !transformedProduct.energyLabel)) {
    // Transform to basic product - Energy Label
    let energySymbol;
    let energyLabel;
    if (productData.classifications) {
      const energy =
        productData.classifications.length > 0
          ? filter(productData.classifications, (c) => c[2] === 'InkclubClassification/1.0/lightclub.energyclass')
          : [];
      if (energy.length > 0) {
        try {
          energySymbol = energy[0][1];
          energySymbol = energySymbol.replace(/\+/g, '-plus');
          energyLabel = energy[0][0].replace('<b>', '').replace('</b>', '');
        } catch {
          energySymbol = undefined;
        }
      }
      transformedProduct.energySymbol = energySymbol;
      transformedProduct.energyLabel = energyLabel;
    }

    transformedProduct.slimDTO = true;
  }

  return transformedProduct;
};

export const getEmptyProduct = () => ({
  code: '',
  name: '',
  url: '',
  thumbnails: [],
  gallery: [],
  mainImg: [
    {
      url: '#',
    },
  ],
  summary: '',
  productStockStatus: {
    availability: '',
    stockStatusDescription: '',
  },
  price: {},
  costPerPage: {},
  title: '',
  metaTitle: '',
  metaDescription: '',
  description: '',
  keywords: '',
  classifications: [],
});

export default getProduct;

