import React from 'react'
import { ProductOneBuyStatus } from '@spa-core/store/products/constants'
import { SlimProductWithOneBuyStatus } from '../../../interfaces'
import { RenderCardsPayload } from '../interfaces'
import Card from '../Card'
import { SlimProduct } from '@spa-core/store/products/interfaces'

export const renderCards = ({
    slimProducts,
    slimProductsWithOneBuyStatus,
    wrapperClassName,
    enableTestFreaks,
    sendBuyTrackingTriggerName,
    isRowCard,
}: RenderCardsPayload): React.ReactNode[] => {
    if (slimProducts) {
        return slimProducts.map((slimProduct: SlimProduct, index: number) => {
            const key: string = `card-${slimProduct.code}-${index}`
            const testFreaksItem: React.ReactNode = enableTestFreaks ? getTestFreaksItem(slimProduct.code) : undefined
            const card: React.ReactNode = (
                <Card
                    key={key}
                    slimProduct={slimProduct}
                    testFreaksItem={testFreaksItem}
                    buyTrackingTriggerName={sendBuyTrackingTriggerName ? slimProduct.name : undefined}
                    isRowCard={isRowCard}
                />
            )
            return wrapperClassName ? (
                <div key={`card-wrapper-${key}`} className={wrapperClassName}>
                    {card}
                </div>
            ) : (
                card
            )
        })
    }
    return slimProductsWithOneBuyStatus.map(({ slimProduct, oneBuyStatus }: SlimProductWithOneBuyStatus, index: number) => {
        const key: string = `${slimProduct.code}${oneBuyStatus !== ProductOneBuyStatus.NONE ? oneBuyStatus : ''}-${index}`
        const testFreaksItem: React.ReactNode = enableTestFreaks ? getTestFreaksItem(slimProduct.code) : undefined
        const card: React.ReactNode = (
            <Card
                key={key}
                slimProduct={slimProduct}
                oneBuyStatus={oneBuyStatus}
                testFreaksItem={testFreaksItem}
                buyTrackingTriggerName={sendBuyTrackingTriggerName ? slimProduct.name : undefined}
                isRowCard={isRowCard}
            />
        )
        return wrapperClassName ? (
            <div key={`card-wrapper-${key}`} className={wrapperClassName}>
                {card}
            </div>
        ) : (
            card
        )
    })
}

const getTestFreaksItem = (productCode: string): React.ReactNode => (
    <div className="h-3.5">
        <div className="testfreaks-item" data-product-id={productCode}></div>
    </div>
)

export const initTestFreaksForCards = (): void => {
    setTimeout(() => {
        const testFreaks: any[] = window['testFreaks'] || []
        testFreaks.push(['load', 'items'])
    }, 500)
}
