import classNames from 'classnames'
import React from 'react'
import Slick from 'react-slick'
import Icon, { Icons } from '@ui-elem/Icon/Icon'

enum Styles {
    SHOW_DOTS_OUTSIDE = 'show_dots_outside',
}
const AUTOPLAY_SPEED_FALLBACK: number = 10000

const NextArrow = ({ onClick = () => {} }) => (
    <Icon
        icon={Icons.AngleRight}
        size={38}
        onClick={onClick}
        className={classNames('pr-2 cursor-pointer z-10 absolute -right-4 top-1/2 e2e-ver-next-icon')}
    />
)

const PrevArrow = ({ onClick = () => {} }) => (
    <Icon
        icon={Icons.AngleLeft}
        size={38}
        onClick={onClick}
        className={classNames('pl-2 cursor-pointer z-10 absolute -left-4 top-1/2 mr-4 e2e-ver-prev-icon')}
    />
)

export type Props = {
    children: React.ReactNode[]
    autoPlay?: boolean
    autoPlaySpeed?: number
    arrows?: boolean
    ssrKey?: string
    wrapperTheme?: string
    dotsOutsideComponent?: boolean
    showDots?: boolean
    initialSlide?: number
    slidesToShow?: number
    setSelectedImageIndex?: (index: number) => void
    infinite?: boolean
}

const Carousel: React.FC<Props> = ({
    children,
    autoPlaySpeed,
    infinite = true,
    arrows = true,
    initialSlide = 0,
    autoPlay = true,
    slidesToShow = 1,
    ssrKey = '',
    dotsOutsideComponent = false,
    showDots = true,
    setSelectedImageIndex = () => {},
}) => {
    const settings: any = {
        initialSlide,
        dots: showDots,
        arrows: arrows,
        infinite: infinite,
        slidesToShow,
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: autoPlay,
        autoplaySpeed: autoPlaySpeed || AUTOPLAY_SPEED_FALLBACK,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        afterChange: setSelectedImageIndex,
    }

    return (
        <div data-ssr-key={ssrKey}>
            <div className={'e2e-ver-carousel'}>
                {children.length > 1 ? (
                    <Slick {...settings} className={classNames(dotsOutsideComponent ? Styles.SHOW_DOTS_OUTSIDE : '')}>
                        {children}
                    </Slick>
                ) : (
                    <>{children}</>
                )}
            </div>
        </div>
    )
}

export default Carousel
