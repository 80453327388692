import React from 'react'
import _unescape from '../decode-ent'

export const getRefinedValue = (value: string, allowedTags: string[]): string => {
    let tagsRegex: string = ''
    allowedTags.forEach((tag: string) => {
        if (tag === 'br') {
            tagsRegex += '(?!br/)(?!br)'
        } else {
            tagsRegex = `${tagsRegex}(?!${tag})(?!/${tag})`
        }
    })
    const replaceString: RegExp = new RegExp('<(' + tagsRegex + 's*/?)[^>]+>', 'g')
    return value.replace(replaceString, '')
}

interface Props {
    value?: string
    allowedTags?: string[]
    renderHtml?: boolean
    className?: string
}

const CleanText = ({ renderHtml, allowedTags, className = '', value = '' }: Props): React.ReactNode => {
    value = allowedTags?.length ? getRefinedValue(value, allowedTags) : value

    const valueWithoutUmlats: string = value ? _unescape(value) : ''
    const cleanValues: string[] = valueWithoutUmlats.split(/<p.*?>(.*?)<\/p>/).filter(Boolean)

    if (renderHtml === true) {
        return cleanValues?.map((cleanValue: string, index: number) => (
            <span key={'row' + index} className={className} dangerouslySetInnerHTML={{ __html: cleanValue }} />
        ))
    }
    return valueWithoutUmlats
}

export default CleanText
