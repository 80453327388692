import React, { useEffect, useState } from 'react'
import { t } from '@spa-core/locale'
import Deck, { DeckColumnsDesktop, DeckColumnsMobile, DeckGap } from '@ui-elem/Deck/Deck'
import Button, { IconAlignments, ButtonColors } from '@ui-elem/Button/Button'
import { Icons } from '@ui-elem/Icon/Icon'
import Link from '@ui-elem/Link'
import { useDispatch, useSelector } from 'react-redux'
import { Store } from '@spa-core/store'
import { NAME as productsReducerName } from '@spa-core/store/products/constants'
import { createSelector } from 'reselect'
import { fetchRepurchaseProductData } from '@spa-core/store/products/actions'
import { SlimProduct } from '@spa-core/store/products/interfaces'
import classNames from 'classnames'
import { initTestFreaksForCards, renderCards } from '../../Card/utils'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import Spinner from '@ui-elem/Spinner'

enum Styles {
    LAST_PURCHSE_COLOR = 'last_purchase_color',
}

type ComponentState = {
    repurchasebleProducts: SlimProduct[]
    sessionConfig: SessionConfig
}

type Props = {
    isPersonalizedCockpit: boolean
    loading?: boolean
    setShowRepurchaseProductsSection?: Function
}

const LastPurchase: React.FC<Props> = ({ isPersonalizedCockpit, loading, setShowRepurchaseProductsSection }) => {
    const repurchasebleProductsStore = ({ reducers }: Store) => reducers[productsReducerName].repurchasebleProducts
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [repurchasebleProductsStore, sessionConfigStore],
        (repurchasebleProducts, sessionConfig): ComponentState => ({
            repurchasebleProducts,
            sessionConfig,
        }),
    )

    const { repurchasebleProducts, sessionConfig }: ComponentState = useSelector(selector)

    const [loadedRepurchaseProductData, setLoadedRepurchaseProductData] = useState<boolean>(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!loadedRepurchaseProductData) {
            dispatch(fetchRepurchaseProductData())
            setLoadedRepurchaseProductData(false)
        }
    }, [dispatch, fetchRepurchaseProductData, setLoadedRepurchaseProductData])

    useEffect(() => {
        initTestFreaksForCards()
    }, [sessionConfig.enableTestFreaks])

    return (
        <div className={classNames(Styles.LAST_PURCHSE_COLOR, 'e2e-ver-last-purchase-list')}>
            {!isPersonalizedCockpit ? (
                <div className="p-2">
                    <h3 className="text-center md:text-left font-bold">
                        {t('phrase.mypages.lastPurchase') !== 'phrase.mypages.lastPurchase'
                            ? t('phrase.mypages.lastPurchase')
                            : 'Senaste köp'}
                    </h3>
                </div>
            ) : null}
            {loading ? (
                <Spinner />
            ) : repurchasebleProducts?.length > 0 ? (
                <div className={!isPersonalizedCockpit ? 'p-2' : ''}>
                    <div className="p-1">
                        <Deck
                            deckColumnsDesktop={DeckColumnsDesktop.COLUMNS_1}
                            deckColumnsMobile={DeckColumnsMobile.COLUMNS_2}
                            deckGap={DeckGap.GAP_1}
                        >
                            {renderCards({
                                slimProducts: repurchasebleProducts.slice(0.4),
                                enableTestFreaks: sessionConfig.enableTestFreaks,
                                sendBuyTrackingTriggerName: true,
                                isRowCard: true,
                            })}
                        </Deck>
                        {isPersonalizedCockpit ? (
                            <div className={'w-full text-center justify-center flex py-2'}>
                                <div className={'flex'}>
                                    <Button
                                        buttonIcon={Icons.Times}
                                        iconAlignment={IconAlignments.RIGHT}
                                        buttonColor={ButtonColors.SECONDARY}
                                        className={'px-12 py-4 mr-4'}
                                        onClick={() => setShowRepurchaseProductsSection(false)}
                                        buttonText={t('popup.close')}
                                    />
                                    <Link to={'/my-account/purchasedProducts'}>
                                        <Button
                                            buttonColor={ButtonColors.PRIMARY}
                                            className={'px-12 py-4'}
                                            buttonText={t('navigation.link.showAll')}
                                        />
                                    </Link>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <div className="p-4 text-center m-auto">{t('text.account.orderHistory.noOrders')}</div>
            )}
        </div>
    )
}

export default LastPurchase
