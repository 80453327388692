import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getComponentTheme } from '@ic-theme'
import ColumnCard from './ColumnCard'
import RowCard from './RowCard'
import { get1KrSticker } from '@spa-ec-js/displayComponents/Bubble/SlimBubble'
import { SlimProduct } from '@spa-core/store/products/interfaces'
import { ProductOneBuyStatus } from '@spa-core/store/products/constants'
import { addToCart } from '@spa-core/store/cart/actions'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { CardTheme, NAME } from './Card.theme'
import getOneBuyStatus from '@spa-ec-js/util/oneBuyStatus'
import { formatPrice } from '@spa-core/locale'

const theme: CardTheme = getComponentTheme<CardTheme>(NAME, {
    showEnKrBackground: false,
})

enum Styles {
    CARD_BACKGOUND = 'card_background',
    ENKR_BACKGROUND = 'enkr_background',
    CARD_DISSCOUNT_CONFIG = 'card_disscount_config',
    CARD_DISSCOUNT_POSITION = 'card_disscount_position',
    CARD_INFO_MESSAGE_CONFIG = 'card_info_message_config',
    CARD_INFO_MESSAGE_POSITION = 'card_info_message_position',
}

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    slimProduct: SlimProduct
    width?: number
    showBuyPanel?: boolean
    isRowCard?: boolean
    buyTrackingTriggerName?: string
    oneBuyStatus?: ProductOneBuyStatus
    testFreaksItem?: React.ReactNode
}

const Card: React.FC<Props> = ({
    slimProduct,
    width,
    buyTrackingTriggerName,
    oneBuyStatus: oneBuyStatusOverride,
    testFreaksItem,
    showBuyPanel = false,
    isRowCard = false,
}) => {
    const dispatch = useDispatch()

    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    const oneBuyStatus: ProductOneBuyStatus = useMemo(
        () =>
            oneBuyStatusOverride ||
            getOneBuyStatus(
                sessionConfig.oneBuyOrderPlacedMap,
                sessionConfig.oneBuyUserGroupMap,
                slimProduct.oneBuyOnlyProduct,
                slimProduct.oneBuyUserGroupId,
            ),
        [
            oneBuyStatusOverride,
            sessionConfig.oneBuyOrderPlacedMap,
            sessionConfig.oneBuyUserGroupMap,
            slimProduct.oneBuyOnlyProduct,
            slimProduct.oneBuyUserGroupId,
        ],
    )

    const hasOneKronaSticker: boolean = useMemo(
        () =>
            slimProduct.oneBuyOnlyProduct &&
            (oneBuyStatus === ProductOneBuyStatus.SHOW || oneBuyStatus === ProductOneBuyStatus.DISABLED),
        [slimProduct.oneBuyOnlyProduct, oneBuyStatus],
    )

    const discountedPrice: number = useMemo(
        () => (hasOneKronaSticker ? slimProduct.oneBuyOnlyPromotionPrice : slimProduct.discountedPrice),
        [hasOneKronaSticker, slimProduct.oneBuyOnlyPromotionPrice, slimProduct.discountedPrice],
    )

    const hasDiscount: boolean = useMemo(
        () =>
            discountedPrice !== undefined &&
            discountedPrice !== null &&
            discountedPrice !== slimProduct.price &&
            slimProduct.price - discountedPrice > 0.5,
        [discountedPrice, slimProduct.price],
    )

    const formattedDiscountedPrice: string = useMemo(() => formatPrice(discountedPrice), [discountedPrice])

    const [subscriptionOpen, setSubscriptionOpen] = useState<boolean>(false)

    let topRightPromo: React.ReactNode
    let topLeftPromo: React.ReactNode

    if (hasOneKronaSticker) {
        topRightPromo = get1KrSticker('m', 'top_right', 'pt-2 pr-2')
    }
    if (!!slimProduct.infoMessage) {
        topLeftPromo = (
            <div className={Styles.CARD_INFO_MESSAGE_POSITION}>
                <div className={Styles.CARD_INFO_MESSAGE_CONFIG}>{slimProduct.infoMessage}</div>
            </div>
        )
    } else if (!hasOneKronaSticker && hasDiscount) {
        // If there is no 1kr or info message but a discount, display as bubble in the top right spot.
        const percentDiscount: number = Math.round((100 * (slimProduct.price - discountedPrice)) / slimProduct.price)
        topRightPromo = (
            <div className={Styles.CARD_DISSCOUNT_POSITION}>
                <div className={Styles.CARD_DISSCOUNT_CONFIG}>{`-${percentDiscount}%`}</div>
            </div>
        )
    }

    let buyButtonHandler = (currentlyAddingKey: string = undefined) => {
        dispatch(
            addToCart({
                quantity: 1,
                productCode: slimProduct.code,
                isEnKrona: hasOneKronaSticker,
                buyTrackingTriggerName,
                currentlyAddingKey,
            }),
        )
    }

    /**
     * if popup is active, choose button behavior for the buttons
     * if popup should contain buy panel, set popup to be activated when clicking on the but button.
     */
    if (showBuyPanel) {
        buyButtonHandler = () => setSubscriptionOpen(true)
    }

    const resolvedBackground: string =
        (hasOneKronaSticker && theme.showEnKrBackground) || slimProduct.tags?.includes('highlighted')
            ? Styles.ENKR_BACKGROUND
            : Styles.CARD_BACKGOUND

    const price: string = slimProduct.price ? formatPrice(slimProduct.price) : ''

    if (isRowCard) {
        return (
            <RowCard
                slimProduct={slimProduct}
                buyButtonHandler={buyButtonHandler}
                subscriptionButtonHandler={() => setSubscriptionOpen(true)}
                resolvedBackground={resolvedBackground}
                price={price}
                formattedDiscountedPrice={formattedDiscountedPrice}
                hasDiscount={hasDiscount}
                topRightPromo={topRightPromo}
                topLeftPromo={topLeftPromo}
                oneBuyStatus={oneBuyStatus}
                subscriptionIsOpen={subscriptionOpen}
                closeSubscribePopup={() => setSubscriptionOpen(false)}
                showBuyPanel={showBuyPanel}
                testFreaksItem={testFreaksItem}
            />
        )
    }

    return (
        <ColumnCard
            width={width}
            slimProduct={slimProduct}
            resolvedBackground={resolvedBackground}
            topRightPromo={topRightPromo}
            topLeftPromo={topLeftPromo}
            price={price}
            formattedDiscountedPrice={formattedDiscountedPrice}
            hasDiscount={hasDiscount}
            subscriptionIsOpen={subscriptionOpen}
            subscriptionButtonHandler={() => setSubscriptionOpen(true)}
            buyButtonHandler={buyButtonHandler}
            oneBuyStatus={oneBuyStatus}
            showBuyPanel={showBuyPanel}
            closeSubscribePopup={() => setSubscriptionOpen(false)}
            testFreaksItem={testFreaksItem}
        />
    )
}

export default Card
