import { CustomerFeedback } from '@spa-core/store/customer-feedback/interfaces'
import React from 'react'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import { t, formatDate } from '@spa-core/locale'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

enum Style {
    customerFeedbackItemColor = 'customer_feedback_item_color',
    customerFeedbackQuoteIconColor = 'customer_feedback_quote_icon_color',
    customerFeedbackUserIconColor = 'customer_feedback_user_icon_color',
}
type Props = {
    feedback: CustomerFeedback
    isCustomerFeedbackPage?: boolean
    link?: boolean
}

const CustomerFeedbackItem: React.FC<Props> = ({
    feedback: { testimonialText, customerNumber, postedOn },
    isCustomerFeedbackPage = false,
    link = false,
}) => {
    const customerFeedbackItem: React.ReactElement = (
        <div className="flex-none">
            <div
                className={classNames(
                    'flex rounded-md p-4',
                    isCustomerFeedbackPage ? 'flex-col w-full' : 'flex-row w-full md:w-72',
                    Style.customerFeedbackItemColor,
                )}
            >
                <Icon icon={Icons.QuoteRight} size={30} className={Style.customerFeedbackQuoteIconColor} />
                <div className={classNames(isCustomerFeedbackPage ? 'w-full' : 'w-60 pr-2', 'flex flex-col ml-2')}>
                    <div
                        className={classNames(
                            isCustomerFeedbackPage ? 'ml-4 mb-2 md:mb-0 text-base' : 'my-2 line-clamp-2 min-h-10 text-sm',
                        )}
                    >
                        {testimonialText}
                    </div>
                    <div className={'flex flex-row justify-end'}>
                        <div className={'flex flex-col mr-2'}>
                            <span className={'text-sm font-bold'}>{`${t('customerNumberAbbreviated')} ${customerNumber}`}</span>
                            <span className={'text-xs'}>{formatDate(new Date(Number(postedOn)))}</span>
                        </div>
                        <Icon icon={Icons.UserCircle} size={40} className={Style.customerFeedbackUserIconColor} />
                    </div>
                </div>
            </div>
        </div>
    )
    if (link) {
        return <Link to={'/feedback'}>{customerFeedbackItem}</Link>
    }
    return customerFeedbackItem
}

export default CustomerFeedbackItem
