import React from 'react'
import ContentSlot from '@spa-ec/components/ContentSlot/ContentSlot'
import CustomerFeedback from '@spa-ec/components/CustomerFeedback/CustomerFeedback'
import { Template } from './ContentPage'
import { Position } from '@spa-core/store/content-slots/constants'

interface Props {
    pageId: string
    template: Template
}

export const Slots = ({ pageId, template }: Props): React.ReactNode => (
    <>
        <ContentSlot key={pageId + '---TopBannerArea'} position={Position.TOP_BANNER_AREA} pageId={pageId} />
        <ContentSlot key={pageId + '---Section1'} position={Position.SECTION_1} pageId={pageId} />
        {template === Template.CUSTOMER_FEEDBACK ? (
            <>
                <ContentSlot key={pageId + '---TopContent'} position={Position.TOP_CONTENT} pageId={pageId} />
                <CustomerFeedback isCustomerFeedbackPage={true} />
                <ContentSlot key={pageId + '---BottomContent'} position={Position.BOTTOM_CONTENT} pageId={pageId} />
            </>
        ) : null}
    </>
)
