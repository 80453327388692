import PropTypes from 'prop-types';
import React from 'react';
import { getThemeFactory } from '@ic-theme';
import { t } from '@spa-core/locale';
import * as cls from './SlimBubble.styles';
import { NAME as appReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

const theme = getThemeFactory('Bubble', {
  oneKrStickerImageSize: 's',
  oneKrStickerImageSizeIpad: 's',
  oneKrStickerImageSizeMobile: 's',
  conf: {
    showOneKronaSticker: false,
  },
});

export const get1KrSticker = (imageSize, position, bubbleClassName) => {
  const th = theme();
  const sessionConfig = getReducer(appReducerName).sessionConfig;
  const currentCountry = sessionConfig.countryIso ? sessionConfig.countryIso.toLowerCase() : '';
  return (
    <img
      src={`${sessionConfig.themeResourcePath}/${th.oneKrSticker.rootPath}${
        th.oneKrSticker.sticker[currentCountry] ? th.oneKrSticker.sticker[currentCountry] : th.oneKrSticker.sticker.default
      }`}
      className={`absolute promoImg ${cls['discount-bubble-image']} ${cls[position]} ${
        cls[imageSize ? 'size-' + imageSize : th.oneKrStickerImageSize]
      } ${bubbleClassName}`}
    />
  );
};

export default class SlimBubble extends React.Component {
  static get propTypes() {
    return {
      oneKronaSticker: PropTypes.bool,
      discountPercentage: PropTypes.any,
      productInfoMessage: PropTypes.string,
      background: PropTypes.string,
      color: PropTypes.string,
      borderRadius: PropTypes.string,
      size: PropTypes.oneOf(['s', 'm', 'l', 'xs']),
      imageSize: PropTypes.oneOf(['s', 'm', 'l', 'xs']),
    };
  }

  render() {
    const p = this.props;
    const th = theme();
    let discountBubble = null;
    if (p.oneKronaSticker && th.conf.showOneKronaSticker) {
      discountBubble = get1KrSticker(p.imageSize, p.position, p.bubbleClassName);
    } else if (p.discountPercentage > 1) {
      discountBubble = (
        <div className="discount flex justify-center items-center">{t('-' + Math.floor(p.discountPercentage) + '%')}</div>
      );
    } else if (p.productInfoMessage) {
      discountBubble = <div className="productInfo">{p.productInfoMessage}</div>;
    }
    if (!discountBubble) {
      return null;
    }
    return (
      <div className={`relative e2e-slim-bubble ${p.fitContent ? cls.content : ''}`}>
        {p.children}
        {discountBubble}
      </div>
    );
  }
}

SlimBubble.defaultProps = {
  background: 'primary',
  color: 'secondary',
  oneKronaSticker: false,
  fitContent: true,
};
