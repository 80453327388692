import { Icons } from '@ui-elem/Icon/Icon'

export const NAME: string = 'StockStatus'

export type StockStatusTheme = {
    iconName?: {
        BUYABLE?: Icons
        NOT_BUYABLE?: Icons
        PROLONGED_DELIVERY?: Icons
    }
    iconType?: {
        BUYABLE?: string
        NOT_BUYABLE?: string
        PROLONGED_DELIVERY?: string
    }
    showIcon?: boolean
}
