import React from 'react'
import { NavLink } from 'react-router-dom'
import { SlimProduct } from '@spa-core/store/products/interfaces'
import { Store } from '@spa-core/store'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { createSelector } from 'reselect'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { CardDescriptionTheme, NAME } from './CardDescription.theme'
import { getComponentTheme } from '@spa-core/theme/theme'

const theme: CardDescriptionTheme = getComponentTheme<CardDescriptionTheme>(NAME, {
    hideShortCopyText: false,
})

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    slimProduct: SlimProduct
    searchTerm?: string
    textLeft?: boolean
}

const CardDescription: React.FC<Props> = ({ slimProduct, searchTerm, textLeft }) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    const productUrl = searchTerm
        ? slimProduct.url + '?searchTerms=' + decodeURIComponent(searchTerm).toLowerCase()
        : slimProduct.url
    const titleLength: number = slimProduct.title?.length
    const title: string = slimProduct.title
    const sCopyDesktop: string = slimProduct?.text?.sCopyDesktop || ''

    return (
        <>
            {titleLength ? (
                <NavLink to={productUrl} key="CardDescription-header-description" className={'min-h-6 md:min-h-16'}>
                    <h2 className={'pt-1 md:pt-4 text-base font-bold'}>
                        <span
                            className={classNames(
                                textLeft ? 'md:text-left' : '',
                                theme.hideShortCopyText ? 'line-clamp-2' : 'line-clamp-1 md:line-clamp-2',
                                'text-center',
                            )}
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                    </h2>
                </NavLink>
            ) : null}

            {sessionConfig.enableSolrDocScore && slimProduct.solrDocScore ? (
                /**
                 * Used for debugging solrDocScore
                 * TODO:
                 * Remove when debugging is done
                 */
                <div className={'flex justify-center p-1 m-1 radius-md bg-slate-300'}>
                    <span>{slimProduct.solrDocScore}</span>
                </div>
            ) : null}

            {/* Do not display on tobacco product, in favor of bracet price component */}
            {!slimProduct.isSnusProduct && !theme.hideShortCopyText ? (
                <NavLink to={productUrl} key="CardDescription-description" className={'min-h-12'}>
                    {/* Product description */}
                    <span
                        className={classNames(textLeft ? 'md:text-left' : 'text-center', 'line-clamp-2 text-center')}
                        dangerouslySetInnerHTML={{ __html: sCopyDesktop }}
                    />
                </NavLink>
            ) : null}
        </>
    )
}

export default CardDescription
