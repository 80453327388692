import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Icon from '@ui-elem/Icon/Icon';
import * as cls from './Input.styles';

const hasValue = (v) => typeof v !== 'undefined' && v !== null && v.length > 0;

const translateIconName = (name) => {
  switch (name) {
    case 'email':
      return 'envelope';
    case 'campaign':
      return 'gift';
    case 'password':
      return 'key';
    default:
      return name;
  }
};

export default class Input extends React.Component {
  /**
   * propTypes
   * @property {string} src
   * @property {string} centered
   * @property {bool} mandatory
   */
  static get propTypes() {
    return {
      // onClick: PropTypes.func,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      fluid: PropTypes.bool,
      password: PropTypes.bool,
      id: PropTypes.string,
      value: PropTypes.string,
      className: PropTypes.string,
      disabled: PropTypes.bool,
      icon: PropTypes.string,
      placeholder: PropTypes.string,
      errorMessage: PropTypes.string,
      successMessage: PropTypes.string,
      mandatory: PropTypes.bool,
      maxLength: PropTypes.number,
      iconPosition: PropTypes.oneOf(['left', 'right']),
      autocomplete: PropTypes.string,
    };
  }

  constructor(props) {
    super(props);
    this.state = { dirty: false, focused: false };
    this.onLocalBlur = this.onLocalBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  onLocalBlur(key, val) {
    this.setState({ dirty: true, focused: false });
    this.props.onBlur(key, val);
  }

  onFocus() {
    this.setState({ focused: true });
  }

  render() {
    const p = this.props;
    let placeholderVal = p.placeholder;
    if (p.mandatory === true) {
      placeholderVal += '*';
    }
    let fluidClasses = '';
    if (p.fluid) {
      fluidClasses = 'fluid';
    }
    let hasValClasses = '';
    if (hasValue(p.value) || this.state.focused) {
      hasValClasses = 'has-val';
    }
    let icon = '';
    if (p.icon) {
      icon = translateIconName(p.icon);
    }

    let status = '';
    let message = placeholderVal;
    if (p.successMessage && p.successMessage !== '' && hasValue(p.value)) {
      status = 'success';
      icon = 'check';
    }
    if (p.errorMessage) {
      status = 'error';
      icon = '';
      if (p.icon) {
        icon = translateIconName(p.icon);
      }
    }
    if (this.state.dirty || p.value || p.value === null) {
      if (status === 'success') {
        message = p.successMessage;
      } else if (status === 'error') {
        message = p.errorMessage;
      } else {
        message = placeholderVal;
      }
    } else {
      status = '';
    }

    if (p.disabled) {
      status = 'disabled';
    }

    let type = p.password ? 'password' : 'text';

    const value = p.value || '';
    const maxLength = p.maxLength || '';
    return (
      <div className={classnames(p.className, cls.input, cls[p.size])}>
        <div className={classnames(cls.inner, cls[status], status ? `e2e-ver-${status}` : '', cls[icon])}>
          {icon && p.iconPosition === 'left' ? (
            <Icon
              className={classnames(cls.icon, cls[icon], cls.left, cls[status], status ? `e2e-ver-${status}` : '')}
              icon={icon}
              size={p.size}
              color={''}
            />
          ) : null}
          <input
            id={p.id}
            data-testid={p.id}
            className={classnames(
              cls[fluidClasses],
              cls['ic-ui-input'],
              cls[hasValClasses],
              hasValClasses ? 'e2e-ver-has-val' : '',
              icon && p.iconPosition === 'left' ? cls.pad_icon_left : ''
            )}
            value={value}
            onChange={(e) => {
              p.onChange(p.id, e.target.value);
            }}
            onFocus={this.onFocus}
            onBlur={(e) => {
              this.onLocalBlur(p.id, e.target.value);
            }}
            disabled={p.disabled}
            type={type}
            maxLength={maxLength}
            autoComplete={p.autocomplete ? p.autocomplete : 'on'}
          />
          <span
            className={classnames(
              cls.placeholder,
              'e2e-ver-placeholder',
              'placeholder',
              hasValClasses ? 'e2e-ver-has-val' : '',
              cls[hasValClasses],
              icon && p.iconPosition === 'left' ? cls.pad_icon_left : '',
              icon && p.iconPosition === 'left' ? cls.left : cls.right
            )}
          >
            {message}
          </span>
          {icon && p.iconPosition === 'right' ? (
            <Icon
              className={classnames(cls.icon, cls.right, cls[icon], cls[status], status ? `e2e-ver-${status}` : '', cls.right)}
              icon={icon}
              size={p.size}
              color={status === 'disabled' ? 'none' : 'pale'}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
Input.defaultProps = {
  fluid: false,
  password: false,
  disabled: false,
  color: 'primary',
  onBlur: () => {},
  errorMessage: '',
  successMessage: '',
  value: '',
  mandatory: false,
  iconPosition: 'left',
};
